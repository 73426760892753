document.addEventListener('DOMContentLoaded', function () {
    let form1 = document.getElementById('form1');
    let email1 = document.getElementById('email1');
    let form2 = document.getElementById('form2');
    let email2 = document.getElementById('email2');

    function validateEmail(email) {
        // A simple email validation regex pattern
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    function validateAndSend(email) {
        if (!validateEmail(email)) {
            alert('Hibás email cím!');
            return;
        }

        // Create the request body
        let requestBody = {
            email: email
        };

        // Send the POST request
        let xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://tropicana.szarnyseged.hu/api/license/signup', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 201) {
                    window.location.href = 'https://szarnyseged.hu/letoltes';
                } else {
                    window.location.href = 'https://szarnyseged.hu/hiba';
                }
            }
        };
        xhr.send(JSON.stringify(requestBody));
    }

    form1.addEventListener('submit', function (event) {
        event.preventDefault();
        validateAndSend(email1.value);
    });

    form2.addEventListener('submit', function (event) {
        event.preventDefault();
        validateAndSend(email2.value);
    });
});
